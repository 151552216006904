import React from "react";

export default ({ color }) => (
  <svg viewBox="0 0 30.7 22">
    <path
      fill={color}
      d="M20.9,1h-3c-2.8,0-5,2.2-5,5v3h-3v4h3v8h4v-8h3l1-4h-4V6c0-0.6,0.4-1,1-1h3V1z"
    />
  </svg>
);
